import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useAuthContext } from '../../auth/useAuthContext';
import { getCommandById } from '../../redux/slices/command/commandThunk';
import { confirmStep, removeUpdate, setTreatment } from '../../redux/slices/prothese/protheseSlice';
import { createProthese, createTraitements, getProtheseById, updateProthese, updateTraitements, } from '../../redux/slices/prothese/protheseThunk';
import { getTreatmentById } from '../../redux/slices/treatment/treatmentsThunk';
import { dispatch, useSelector } from '../../redux/store';
import { PATH_DASHBOARD } from '../../routes/paths';
import PatientNewForm from '../../sections/@dashboard/patient/PatientNewForm';
import PatientSecondStep from '../../sections/@dashboard/patient/PatientSecondStep';
import PatientThirdStep from '../../sections/@dashboard/patient/PatientThirdStep';
import ConfirmDialog from '../confirm-dialog';
import StepperPopup from './StepperPopup/StepperPopup';
import Iconify from '../iconify';
// ----------------------------------------------------------------------
const steps = ['Création carte patient', 'Création prothèse', 'Création d’un ticket'];
export default function LinearAlternativeLabel() {
    const [currentId, setCurrentId] = useState('');
    const navigate = useNavigate();
    const { step, id, treatmentId } = useParams();
    const stepNumber = step ? parseInt(step, 10) : undefined;
    const [selectedDent, setSelectedDent] = useState(0);
    const [lastStepCompleted, setLastStepCompleted] = useState(0);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const lastStepCompletedQuery = queryParams.get('lastStepCompleted');
    const level = stepNumber !== undefined ? stepNumber - 1 : undefined;
    const [activeStep, setActiveStep] = useState(level || 0);
    const [skipped, setSkipped] = useState(new Set());
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openConfirm2, setOpenConfirm2] = useState(false);
    const [load, setLoad] = useState(false);
    const _id = useSelector((state) => state.patient.onData?._id);
    useEffect(() => {
        if ((stepNumber !== undefined && (stepNumber < 1 || stepNumber > 3)) ||
            (stepNumber && stepNumber == 1 && !id) ||
            (stepNumber && stepNumber == 2 && !id) ||
            (stepNumber && stepNumber == 3 && !id && !treatmentId)) {
            navigate(PATH_DASHBOARD.patientForm
                .replace(':step', '1')
                .replace('/:id?', '')
                .replace(':treatmentId?', '') + `?lastStepCompleted=${1}`);
            setActiveStep(0);
        }
    }, [navigate, stepNumber, id]);
    useEffect(() => {
        if (stepNumber && stepNumber > lastStepCompleted) {
            setLastStepCompleted(stepNumber);
        }
        if (lastStepCompletedQuery) {
            setLastStepCompleted(+lastStepCompletedQuery);
        }
        return;
    }, [stepNumber]);
    useEffect(() => {
        if (+stepNumber > +lastStepCompletedQuery) {
            navigate(PATH_DASHBOARD.patientForm
                .replace(':step', `${lastStepCompletedQuery || 1}`)
                .replace(':id?', `${id ? id : ''}`)
                .replace(':treatmentId?', `${treatmentId ? treatmentId : ''}`) +
                `?lastStepCompleted=${lastStepCompleted}`);
        }
    }, [lastStepCompletedQuery]);
    const isStepSkipped = (step) => skipped.has(step);
    function handleClickStepper(index) {
        if (lastStepCompleted > index) {
            navigate(PATH_DASHBOARD.patientForm
                .replace(':step', `${index + 1}`)
                .replace(':id?', `${id ? id : ''}`)
                .replace(':treatmentId?', `${treatmentId ? treatmentId : ''}`) +
                `?lastStepCompleted=${lastStepCompleted}`);
            setActiveStep(index);
        }
    }
    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    const { enqueueSnackbar } = useSnackbar();
    const data = useSelector((state) => state.dent);
    const error = useSelector((state) => state.patient.error);
    const files = data.files;
    const update = data.isUpdate;
    const description = data?.description;
    const updateProthses = data.isUpdate2;
    const protheseId = data.ProtheseId;
    const currentTreatment = data.TreatmentId;
    const currentCommand = data.commandId;
    const forwardCommand = data.forwardCommandView;
    const { user } = useAuthContext();
    const laboratory = user?.roles[0]?.code === 'PROTHESIST'
        ? user?.laboratory?._id
        : user?.roles[0]?.code === 'DENTIST'
            ? user?.cabinet?.laboratory
            : undefined;
    const [num, setNum] = useState(data?.digitalFootprint);
    const [phy, setPhy] = useState(data?.physicalFootprint);
    return (_jsxs(_Fragment, { children: [_jsx(Stepper, { activeStep: activeStep, orientation: "horizontal", sx: { mx: '10%' }, children: steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (_jsx(Step, { onClick: () => {
                            handleClickStepper(index);
                        }, ...stepProps, children: _jsx(StepLabel, { ...labelProps, sx: {
                                color: 'white',
                                borderRadius: '10px',
                                alignItems: 'center',
                                p: '10px 20px',
                                cursor: lastStepCompleted > index ? 'pointer !important' : 'default',
                                background: index < activeStep
                                    ? 'linear-gradient(120deg, #27E7B4 21.17%, #5E96E0 79.91%), #6D758F'
                                    : index === activeStep
                                        ? 'linear-gradient(120deg, #2788E7 21.17%, #785EE0 79.91%), linear-gradient(0deg, #353E5C 0%, #353E5C 100%), #353E5C'
                                        : '#DCE5ED',
                            }, StepIconComponent: () => index < activeStep ? _jsx(Iconify, { icon: "icons8:checked-2" }) : null, children: _jsx(Typography, { color: index > activeStep ? '#6D758F' : 'white', children: label }) }) }, label));
                }) }), activeStep === steps.length ? (_jsxs(_Fragment, { children: [_jsx(Paper, { sx: {
                            p: 3,
                            my: 3,
                            minHeight: 120,
                            bgcolor: '#F3F7FC',
                        }, children: _jsx(Typography, { sx: { my: 1 }, children: "All steps completed - you're finished" }) }), _jsxs(Box, { sx: { display: 'flex' }, children: [_jsx(Box, { sx: { flexGrow: 1 } }), _jsx(Button, { onClick: handleReset, children: "Reset" })] })] })) : (_jsxs(_Fragment, { children: [_jsx(Paper, { sx: {
                            p: 3,
                            my: 3,
                            minHeight: 120,
                            bgcolor: '#F3F7FC',
                        }, children: activeStep === 0 ? (_jsx(_Fragment, { children: _jsx(PatientNewForm, { setOpenConfirm: setOpenConfirm, setCurrentId: setCurrentId, handleNext: handleNext }) })) : activeStep === 1 ? (_jsxs(_Fragment, { children: [_jsx(PatientSecondStep, { selectedDent: selectedDent, setSelectedDent: setSelectedDent, num: num, setNum: setNum, phy: phy, setPhy: setPhy }), _jsxs(Box, { sx: {
                                        width: '100%',
                                        justifyContent: 'end',
                                        display: 'flex',
                                        padding: '30px',
                                        gap: '15px',
                                    }, children: [_jsx(Button, { color: "error", variant: "outlined", onClick: () => {
                                                navigate(PATH_DASHBOARD.patientForm
                                                    .replace(':step', `${1}`)
                                                    .replace(':id?', `${id ? id : ''}`)
                                                    .replace(':treatmentId?', `${treatmentId ? treatmentId : ''}`) +
                                                    `?lastStepCompleted=${lastStepCompleted}`);
                                                setActiveStep(0);
                                            }, children: "Retourner" }), !error && (_jsx(Button, { color: "info", variant: "outlined", onClick: () => {
                                                setOpenConfirm2(true);
                                            }, children: update ? 'Mise à jour' : 'Enregistrer' }))] })] })) : (_jsx(_Fragment, { children: _jsx(PatientThirdStep, { setActiveStep: setActiveStep, setSelectedDent: setSelectedDent }) })) }), _jsx(Box, { sx: { display: 'flex' } }), _jsx(StepperPopup, { openConfirm: openConfirm, handleNext: handleNext, setOpenConfirm: setOpenConfirm, step: level ? level + 1 : 1, id: currentId })] })), _jsx(ConfirmDialog, { cancelText: `Annuler`, open: openConfirm2, onClose: () => {
                    setOpenConfirm2(false);
                }, title: "Confirmer", content: "Etes-vous s\u00FBr de vouloir soumettre, seuls les \u00E9l\u00E9ments confirm\u00E9s seront envoy\u00E9s", action: _jsx(LoadingButton, { variant: "contained", loading: load, color: "error", onClick: async () => {
                        if (!update) {
                            const confirmedDent = data.dents.filter((option) => option.confirmed === true);
                            if (confirmedDent.length === 0) {
                                enqueueSnackbar('Veuillez compléter au moins une dent', { variant: 'error' });
                                setOpenConfirm2(false);
                                return;
                            }
                            const dents = confirmedDent.map((option) => ({
                                name: option.name,
                                type: option.type,
                                color: option.color,
                                remarks: option.remarks,
                                price: option.price,
                                ombre: option.ombre,
                            }));
                            if (dents[0].color === '') {
                                enqueueSnackbar('La couleur des dents est requise', { variant: 'error' });
                                setOpenConfirm2(false);
                                return;
                            }
                            const params = {
                                patient: data?.patient || _id,
                                dents: dents,
                                description: description,
                            };
                            setLoad(true);
                            const response = (await dispatch(createTraitements({ params })));
                            if (response?.payload?.statusCode === 200) {
                                dispatch(setTreatment(response?.payload?.data?.traitement?._id));
                                dispatch(getTreatmentById({ id: response?.payload?.data?.traitement?._id }));
                                dispatch(confirmStep());
                                if (!updateProthses) {
                                    const protheseParams = {
                                        traitement: response?.payload?.data?.traitement?._id || '' || '',
                                        files,
                                        laboratory,
                                        digitalFootprint: num,
                                        physicalFootprint: phy,
                                    };
                                    const protheseResponse = (await dispatch(createProthese({ params: protheseParams })));
                                    if (protheseResponse?.payload?.statusCode === 200) {
                                        enqueueSnackbar('Traitement créé avec succès!');
                                        const protheseId = protheseResponse?.payload?.data?._id;
                                        setOpenConfirm2(false);
                                        if (step && id) {
                                            setLastStepCompleted(3);
                                            navigate(PATH_DASHBOARD.patientForm
                                                .replace(':step', (3).toString())
                                                .replace(':id?', id)
                                                .replace(':treatmentId?', protheseId || '') + `?lastStepCompleted=${3}`);
                                        }
                                        handleNext();
                                    }
                                    else {
                                        enqueueSnackbar(protheseResponse.payload?.message ||
                                            "Quelque chose s'est mal passé lors de la création de la prothèse !", { variant: 'error' });
                                    }
                                }
                            }
                            else {
                                enqueueSnackbar(response.payload?.message ||
                                    "Quelque chose s'est mal passé lors de la création du traitement !", { variant: 'error' });
                            }
                            setLoad(false);
                        }
                        else {
                            const confirmedDent = data.dents.filter((option) => option.confirmed === true);
                            if (confirmedDent.length === 0) {
                                enqueueSnackbar('Veuillez compléter au moins une dent', { variant: 'error' });
                                setOpenConfirm2(false);
                                return;
                            }
                            const dents = confirmedDent.map((option) => ({
                                name: option.name,
                                type: option.type,
                                color: option.color,
                                remarks: option.remarks,
                                price: option.price,
                                ombre: option.ombre,
                            }));
                            if (dents[0].color === '') {
                                enqueueSnackbar('La couleur des dents est requise', { variant: 'error' });
                                setOpenConfirm2(false);
                                return;
                            }
                            const params = {
                                patient: data?.patient || _id,
                                dents: dents,
                                description: description,
                            };
                            setLoad(true);
                            const response = (await dispatch(updateTraitements({ id: currentTreatment || '', params })));
                            if (response?.payload?.statusCode === 200) {
                                dispatch(confirmStep());
                                dispatch(removeUpdate());
                                if (currentTreatment)
                                    dispatch(getTreatmentById({ id: currentTreatment }));
                                setOpenConfirm2(false);
                            }
                            else {
                                enqueueSnackbar(response.payload?.message ||
                                    "Quelque chose s'est mal passé lors de la mise à jour du traitement !", { variant: 'error' });
                            }
                            if (updateProthses && protheseId) {
                                const protheseParams = {
                                    traitement: currentTreatment || '',
                                    files,
                                    digitalFootprint: num,
                                    physicalFootprint: phy,
                                };
                                setLoad(true);
                                const protheseResponse = (await dispatch(updateProthese({ id: protheseId, params: protheseParams })));
                                if (protheseResponse?.payload?.statusCode === 200) {
                                    enqueueSnackbar('Commande mise à jour avec succès !');
                                    setOpenConfirm2(false);
                                    const protheseData = (await dispatch(getProtheseById({ id: protheseId })));
                                    setLoad(false);
                                    if (forwardCommand && currentCommand) {
                                        dispatch(getCommandById({ id: currentCommand }));
                                        navigate(PATH_DASHBOARD.order.replace(':id', currentCommand));
                                    }
                                    else if (step && id) {
                                        setLastStepCompleted(3);
                                        navigate(PATH_DASHBOARD.patientForm
                                            .replace(':step', (3).toString())
                                            .replace(':id?', id)
                                            .replace(':treatmentId?', protheseData?.payload?.data?.data?._id || '') +
                                            `?lastStepCompleted=${3}`);
                                    }
                                    handleNext();
                                }
                                else {
                                    enqueueSnackbar(protheseResponse.payload?.message ||
                                        "Quelque chose s'est mal passé lors de la mise à jour de la commande !", { variant: 'error' });
                                }
                                setLoad(false);
                            }
                        }
                    }, children: "Confirmer" }) })] }));
}
